<script>
  export let price,
    countryCode,
    companion,
    customerDetails,
    isConsentGiven = false;

  import { url } from "@roxi/routify";
  import * as Sentry from "@sentry/browser";
  import { _ } from "svelte-i18n";
  import StripeSVG from "../images/stripe.svg";
  import PadlockSVG from "../images/padlock.svg";
  import MastercardSVG from "../images/mastercard.svg";
  import VisaSVG from "../images/visa.svg";
  import AmexSVG from "../images/amex.svg";
  import DiscoverNetworkSVG from "../images/discover-network.svg";
  import Ideal from "../images/ideal.svg";
  import SepaDebit from "../images/sepadebit.svg";
  import Bancontact from "../images/bancontact.svg";
  import Eps from "../images/eps.svg";
  import Giropay from "../images/giropay.svg";
  import P24 from "../images/przelewy24.svg";

  import Loader from "./Loader.svelte";
  import Modal from "./Modal.svelte";
  import StripePaymentForm from "./stripe/StripePaymentForm.svelte";
  import PayPalButton from "./paypal/PayPalButton.svelte";
  import ContactUsForm from "../components/ContactUsForm.svelte";

  let showingErrorPopUp = false,
    showingCreateOrderErrorPopUp = false,
    isOpen = false;
  const productName = price?.variant.product.name;
  const isHearth =
    productName.includes("Hearth") || productName.includes("hearth");
  function handleErrorEvent({ detail: error }) {
    Sentry.captureException(error);

    if (error.code === "CreateOrderError") {
      showingCreateOrderErrorPopUp = true;
    } else {
      showingErrorPopUp = true;
    }
  }

  let isLoading = false;
  function startLoading() {
    isLoading = true;
  }

  function stopLoading() {
    isLoading = false;
  }

  if (isConsentGiven) {
    customerDetails.consentData = {
      first_name: customerDetails.firstName,
      last_name: customerDetails.lastName,
      country_code: countryCode,
      version: "1",
      language: localStorage.getItem("enrol-app-locale"),
      agreed: true,
    };
  }
  let orderData;
  $: orderData = {
    price,
    customerDetails,
    countryCode,
    companion,
  };

  let showPaypal = !(
    companion &&
    ["monthly", "annually"].includes(price.variant.payment_frequency)
  );
</script>

<section>
  <h2>{$_("payment.Your Payment Details")}</h2>
  <div class="secure-logos">
    <div class="item powered-by-stripe">
      {$_("payment.Powered By")}
      <StripeSVG />
    </div>
  </div>
  <div class="secure-logos">
    <div class="item ssl">
      <PadlockSVG />
    </div>
    <div class="item">
      <strong>{$_("payment.SSL secure payment.")}</strong>
    </div>
  </div>
  <div class="secure-logos">
    <div class="item">
      <MastercardSVG />
    </div>
    <div class="item">
      <VisaSVG />
    </div>
    <div class="item">
      <AmexSVG />
    </div>
    <div class="item">
      <DiscoverNetworkSVG />
    </div>
  </div>
  {#if localStorage.getItem("enrol-app-locale") != "en"}
    <div class="secure-logos">
      <div class="item">
        <Ideal />
      </div>
      <div class="item">
        <SepaDebit />
      </div>
      <div class="item">
        <Bancontact />
      </div>
      <div class="item">
        <Eps />
      </div>
      <div class="item">
        <Giropay />
      </div>
      <div class="item">
        <P24 />
      </div>
    </div>
  {/if}

  {#if orderData}
    <section class="stripe">
      <StripePaymentForm
        disabled={isLoading}
        {orderData}
        on:loadingstart={startLoading}
        on:loadingstop={stopLoading}
        on:order
        on:error={handleErrorEvent}
      />
    </section>

    {#if showPaypal}
      <section class="paypal">
        <div class="separator">
          <span class="or">{$_("payment.or")}</span>
        </div>
        <PayPalButton
          {orderData}
          on:loadingstart={startLoading}
          on:loadingstop={stopLoading}
          on:order
          on:error={handleErrorEvent}
        />
      </section>
    {/if}
    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >
    <ContactUsForm bind:isOpen />
    <br />
    {#if isHearth}
      <b>Order Confirmation</b><br />
      After payment you will receive an automatic confirmation receipt by email.
      If you have not received anything, please check your junk / spam mailbox.<br
      /><br />

      <b>Provisioning</b><br />
      Your order will be provisioned by: The Oak Tree Press (<b>OTP</b>) on
      behalf of:<br />
      The Order of Bards, Ovates and Druids (<b>OBOD</b>)<br />
      P.O. Box 1333, Lewes, East Sussex. BN7 1DX. UK<br />
      <a href="https://druidry.org" target="_blank">www.druidry.org</a><br /><br
      />

      <b>Enquiries</b><br />
      Should you need to contact us regarding your order, please email us at:
      <a href="mailto:office@druidry.org" target="_blank">office@druidry.org</a>
    {:else}
      {@html $_("payment.consent")}
    {/if}
  {/if}

  <Modal bind:visible={showingCreateOrderErrorPopUp}>
    <h1>
      {@html $_("payment.Sorry, something went wrong with your transaction.")}
    </h1>

    <p class="popup-error-message">
      {@html $_("payment.Please check your internet connection and try again.")}
    </p>

    <p class="popup-error-detail">
      {@html $_(
        "payment.Unfortunately, we can't tell exactly what went wrong. Please don't try to pay a second time, as this may result in you being charged twice."
      )}
    </p>

    <button
      on:click|preventDefault={() => (showingCreateOrderErrorPopUp = false)}
      class="btn btn--primary create-order-error-close"
      >{@html $_("payment.CLOSE")}</button
    >
  </Modal>

  <Modal bind:visible={showingErrorPopUp}>
    <h1>
      {@html $_("payment.Sorry, something went wrong with your transaction.")}
    </h1>

    <p class="popup-error-message">
      {@html $_(
        "payment.Unfortunately, we can't tell exactly what went wrong. Please don't try to pay a second time, as this may result in you being charged twice."
      )}
      {@html $_(
        'payment.Instead, please email our team at <a href="mailto:admin@druidry.org">admin@druidry.org</a>, providing your name and address details, and someone will be in touch promptly to let you know whether your payment has been processed.'
      )}
    </p>
  </Modal>

  <Loader visible={isLoading}>
    <div class="processing">{@html $_("payment.Please wait")} ...</div>
  </Loader>

  <section class="btn-container">
    <div class="btn-container__inside">
      {#if isLoading}
        <button disabled class="btn btn--secondary">{$_("choice.back")}</button>
      {:else}
        <a class="btn btn--secondary" href={$url("./details")}
          >{$_("choice.back")}</a
        >
      {/if}
    </div>
  </section>
</section>

<style>
  @import "../styles/variables.css";

  .secure-logos {
    display: flex;
    align-items: center;
  }

  .item {
    margin: 0 10px 10px 0;
  }

  .stripe {
    background: var(--primary);
    border-radius: 10px;
    padding: var(--baseline);
  }

  .powered-by-stripe {
    background: var(--primary);
    color: var(--white);
    font-weight: bold;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 2rem;
  }

  .separator {
    margin: 1rem 2rem;
    text-align: center;
  }

  .separator::before {
    content: "";
    display: block;
    background: var(--text);
    height: 2px;

    position: relative;
    top: 1.2rem;
  }

  .separator .or {
    display: inline-block;
    background: var(--white);
    padding: 0.5rem;
    text-transform: uppercase;
    position: relative;
  }

  .processing {
    font-size: 52pt;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black;
  }

  .popup-error-detail {
    margin: 0.5em 0;
  }

  .create-order-error-close {
    margin-top: 1em;
    width: 100%;
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }
</style>
