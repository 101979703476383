import { createOrder } from './utils';
import { fetchJSON } from '../utils';

/**
 * We need this to succeed in order to continue with the order, as this is how
 * our system will recognise transactions against this order.
 */
export async function updateOrderWithPayPalID({ id }, paypalOrderID) {
  await fetchJSON(`/api/v1/orders/${id}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify({
      pay_pal_order_id: paypalOrderID
    })
  });
}

/**
 * Returns the details of the order.
 */
export async function confirmPayPalOrder(paypalOrder) {
  const { data: { order } } = await fetchJSON(`/api/v1/paypal/orders/${paypalOrder.id}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(paypalOrder)
  });

  return order;
}

/**
 * Returns the details of the order.
 */
export async function confirmPayPalSubscription(subscriptionID) {
  const { data: { order } } = await fetchJSON(`/api/v1/paypal/subscriptions/${subscriptionID}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify({ hey: "I'm done" })
  });

  return order;
}

const createPayPalOrderOptions = ({ price, customerDetails, paypalCustomer, countryCode }, { startLoading, stopLoading }, cb) => ({
  async createOrder(data, actions) {
    const { order } = await createOrder({
      method: 'paypal',
      price,
      customerDetails,
      countryCode
    });

    let companionPrice = price.language == "en" ? 120 : 140;
    let productName = order.price.variant.product.title ? order.price.variant.product.title : order.price.variant.product.name;
    let product_description = (customerDetails.CompanionEmail ? `${productName} - With Companion` : productName)
    const paypalOrderID = await actions.order.create({
      payer: paypalCustomer,
      purchase_units: [
        {
          invoice_id: order.id.toString(),
          amount: {
            value: (Number(order.price.value) + (customerDetails.CompanionEmail ? companionPrice : 0)).toFixed(2),
            currency_code: order.price.currency_code
          },
          description: product_description
        }
      ]
    });

    await updateOrderWithPayPalID(order, paypalOrderID);

    return paypalOrderID;
  },
  onError: cb,
  async onApprove(data, actions) {
    startLoading();
    try {
      const paypalOrder = await actions.order.capture();
      const order = await confirmPayPalOrder(paypalOrder);
      cb(null, order);
    } finally {
      stopLoading();
    }
  }
});

const createPayPalSubscriptionOptions = ({ price, paypalCustomer, customerDetails, countryCode }, { startLoading, stopLoading }, cb) => ({
  async createSubscription(data, actions) {
    const paypalSubscriptionID = await actions.subscription.create({
      plan_id: price.pay_pal_plan_id,
      subscriber: paypalCustomer
    });

    await createOrder({
      method: 'paypal',
      price,
      customerDetails,
      countryCode,
      paypalSubscriptionID
    });

    return paypalSubscriptionID;
  },
  onError: cb,
  async onApprove(data, actions) {
    startLoading();
    try {
      const { subscriptionID } = data;
      const order = await confirmPayPalSubscription(subscriptionID);
      cb(null, order);
    } finally {
      stopLoading();
    }
  }
});

export function createPayPalOptions({ price, customerDetails, countryCode }, loaders, cb) {
  const monthly = price.variant.payment_frequency === 'monthly' || price.variant.payment_frequency === 'annually';

  const {
    firstName,
    lastName,
    email
  } = customerDetails;

  const paypalCustomer = {
    name: {
      given_name: firstName,
      surname: lastName
    },
    email_address: email
  };

  if (monthly) {
    return createPayPalSubscriptionOptions({ price, customerDetails, countryCode, paypalCustomer }, loaders, cb);
  } else {
    return createPayPalOrderOptions({ price, customerDetails, countryCode, paypalCustomer }, loaders, cb);
  }
}
