<script>
  export let orderData;
  import { createEventDispatcher, onMount } from "svelte";
  import { createPayPalOptions } from "../../payment/paypal.js";
  import { payPalStore } from "../../stores.js";

  const dispatch = createEventDispatcher();

  function startLoading() {
    dispatch("loadingstart");
  }

  function stopLoading() {
    dispatch("loadingstop");
  }

  let paypalElement, paypalURL;
  let locale = localStorage.getItem("enrol-app-locale");
  switch (localStorage.getItem("enrol-app-locale")) {
    case "en":
      locale = "en_GB";
      break;
    case "fr":
      locale = "fr_FR";
      break;
    case "it":
      locale = "it_IT";
      break;
    case "de":
      locale = "de_DE";
      break;
    case "nl":
      locale = "nl_NL";
      break;
    default:
      locale = "en_GB";
  }

  function onPayPalLoad(paypal) {
    const paypalOptions = createPayPalOptions(
      orderData,
      { startLoading, stopLoading },
      (err, order) => {
        if (err) {
          dispatch("error", err);
        } else {
          dispatch("order", order);
        }
      }
    );

    Object.assign(paypalOptions, {
      style: {
        height: 40,
        color: "silver",
        tagline: false,
        label: "pay",
        locale: locale,
        layout: "vertical",
        disallowed: [paypal.FUNDING.CREDIT, paypal.FUNDING.CARD],
      },
    });

    // Create PayPal button(s) - paypal is a global
    paypal.Buttons(paypalOptions).render(paypalElement);
  }

  onMount(() => {
    payPalStore.subscribe((paypal) => {
      if (paypal) onPayPalLoad(paypal);
    });
  });
</script>

<div class="paypal-buttons" bind:this={paypalElement} />
