<script>
  export let orderData, disabled;
  import { createEventDispatcher, onMount, onDestroy } from "svelte";
  import { confirmStripePayment, stripePayment } from "../../payment/stripe.js";
  import { stripePublicKey, stripePublicKeyEurope } from "config";
  import { _ } from "svelte-i18n";
  import StripeDeclinePopUp from "./StripeDeclinePopUp.svelte";
  import { createOrder, paymentMethods } from "../../payment/utils.js";
  import { Circle3 } from "svelte-loading-spinners";

  const dispatch = createEventDispatcher();

  let stripe,
    card,
    cardElement,
    elements,
    order,
    clientSecret,
    cardError = "",
    cardHolderName = "",
    declineError = null,
    showingDeclinePopUp = false,
    showLoading = true;
  let locale = localStorage.getItem("enrol-app-locale");

  $: {
    if (card) {
      card.update({ disabled });
    }
  }

  function setCardError(event) {
    if (event.error) {
      cardError = event.error.message;
    } else {
      cardError = "";
    }
  }

  function showStripeDeclinePopUp(error) {
    declineError = error;
    showingDeclinePopUp = true;
  }

  function startLoading() {
    dispatch("loadingstart");
  }

  function stopLoading() {
    dispatch("loadingstop");
  }

  /**
   * Disables inputs and tries to make the payment through stripe.  Inputs
   * remain disabled if the payment succeeds.
   */
  async function payByStripe() {
    // Unfocus the currenrtly active element for prevent further form
    // submissions
    document.activeElement && document.activeElement.blur();
    try {
      startLoading();
      const { error, order } = await stripePayment(
        stripe,
        orderData,
        cardHolderName,
        card
      );

      if (order) {
        dispatch("order", order);
      } else {
        stopLoading();
        if (error) showStripeDeclinePopUp(error);
      }
    } catch (e) {
      stopLoading();
      dispatch("error", e);
    }
  }

  // NB. the stripe elements need to be styled in JS.
  const style = {
    base: {
      color: "#32325d",
      fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const fonts = [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700&display=swap",
    },
  ];

  async function createEuropeStripeOrderData(orderData) {
    let response = await createOrder({ ...orderData, method: "stripe" });
    await response;
    order = response.order;
    clientSecret = response.stripeClientSecretID;

    stripe = Stripe(stripePublicKey, {
      locale: localStorage.getItem("enrol-app-locale") || "en",
    });
    const appearance = {
      theme: "stripe",
      labels: "floating",
    };
    const options = {
      layout: {
        type: "tabs",
        defaultCollapsed: false,
      },
      paymentMethodOrder: paymentMethods(),
    };

    elements = stripe.elements({
      clientSecret,
      appearance,
      locale: localStorage.getItem("enrol-app-locale"),
    });
    const paymentElement = elements.create("payment", options);
    showLoading = false;
    paymentElement.mount("#payment-element");
  }

  async function payByStripeAndPaymentMethod() {
    try {
      startLoading();

      const isOnline = order.price.variant.product.code != "text";
      const search = new URLSearchParams(
        [["orderID", order.id], isOnline && ["online", 1]].filter(Boolean)
      );

      elements.submit().then(function (result) {
        if (result.error) {
          showStripeDeclinePopUp(result.error);
          return;
        }
      });

      await stripe
        .confirmPayment({
          clientSecret,
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/success/bardic?${search}`,
          },
          redirect: "if_required",
        })
        .then(async function (result) {
          if (result.error) {
            stopLoading();
            showStripeDeclinePopUp(result.error);
          } else {
            await confirmStripePayment(order);
            dispatch("order", order);
          }
        });
    } catch (e) {
      stopLoading();
      dispatch("error", e);
    }
  }

  onMount(() => {
    // Create an instance of Elements.
    if (localStorage.getItem("enrol-app-locale") === "en") {
      stripe = Stripe(stripePublicKey, {
        locale: "en-GB",
      });
      elements = stripe.elements({ fonts, locale: locale });
      card = elements.create("card", {
        style,
        value: { postalCode: orderData.customerDetails.postCode },
      });
      card.mount(cardElement);
      card.addEventListener("change", setCardError);
    } else {
      createEuropeStripeOrderData(orderData);
    }
  }, locale);

  onDestroy(() => {
    if (card) card.removeEventListener("change", setCardError);
  });
</script>

{#if locale === "en"}
  <form on:submit|preventDefault={payByStripe} method="post">
    <div>
      <div class="card-element" bind:this={cardElement} />
      <div class="error-message">{cardError}</div>
    </div>

    <div class="card-name">
      <input
        {disabled}
        type="text"
        placeholder={$_("payment.Name on Card")}
        required
        bind:value={cardHolderName}
      />
    </div>

    <div class="pay-now">
      <button {disabled} class="btn btn--primary"
        >{$_("payment.Pay Now")}</button
      >
    </div>
  </form>
{:else}
  <form
    id="payment-form"
    on:submit|preventDefault={payByStripeAndPaymentMethod}
    method="post"
  >
    <div id="link-authentication-element">
      <!--Stripe.js injects the Link Authentication Element-->
    </div>
    <div id="payment-element">
      {#if showLoading}
        <div class="circle3-spinner">
          <Circle3 size="60" unit="px" duration="1s" />
        </div>
      {/if}
      <!--Stripe.js injects the Payment Element-->
    </div>
    <div class="pay-now">
      <button {disabled} id="button-text" class="btn btn--primary"
        >{$_("payment.Pay Now")}</button
      >
    </div>
    <div id="payment-message" class="hidden" />
  </form>
{/if}

<StripeDeclinePopUp
  bind:visible={showingDeclinePopUp}
  error={declineError}
  region={orderData.price.region}
/>

<style>
  @import "../../styles/variables.css";

  form {
    background: var(--white);
    padding: var(--baseline);
    border-radius: 4px;
    color: var(--text);
  }

  .error-message {
    padding-top: var(--baseline);
    color: #fa755a;
  }

  .error-message:empty {
    display: none;
  }

  .card-element {
    padding: 1.3ex 0;
  }

  .card-name input {
    border: none;
  }

  .pay-now {
    margin-top: 0.5rem;
  }

  .pay-now .btn {
    width: 100%;
  }

  .circle3-spinner {
    margin-left: 45%;
  }
</style>
